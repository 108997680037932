// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/base/perspective/sidebar/right/RightSidebarTemplate.soy

/**
 * @fileoverview Templates in namespace ts.base.perspective.sidebar.right.RightSidebarTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $rightSidebar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.base.perspective.sidebar.right.RightSidebarTemplate.rightSidebar']) {
    return soy.$$stubsMap['ts.base.perspective.sidebar.right.RightSidebarTemplate.rightSidebar'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="right-sidebar-wrapper"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.right.RightSidebarTemplate.rightSidebar ts/base/perspective/sidebar/right/RightSidebarTemplate.soy:8"' : '') + '><div id="right-sidebar-container" class="ui visible vertical flat right overlay sidebar"><div id="right-sidebar-accordion" class="ui styled fluid accordion"><div id="right-sidebar"></div><div id="coverage-menu-container" class="title active hidden accordion-trigger"><i id="coverage-icon" class="check icon"></i><span class="item__text">Test Coverage</span></div><div id="sidebar-coverage-content" class="content"></div><div id="quality-goal-menu-container" class="title active hidden accordion-trigger"><i id="quality-goal-icon" class="flag checkered icon"></i><span class="item__text">Quality Goal Settings</span></div><div id="sidebar-quality-goal-content" class="content"></div><div id="filter-menu-container" class="title active accordion-trigger"><i id="filter-icon" class="filter icon"></i><span class="item__text">Filter Options</span></div><div id="sidebar-filter-content" class="content active ui form"></div><div id="findings-menu-container" class="title active hidden accordion-trigger"><i id="findings-icon" class="exclamation triangle icon"></i><span class="item__text">Findings</span></div><div id="sidebar-findings-content" class="content"></div><div id="info-menu-container" class="title active accordion-trigger"><i id="info-icon" class="info icon"></i> <span class="item__text">Summary</span></div><div id="sidebar-info-content" class="content active"></div></div></div><div id="right-sidebar-toggle-menu" class="ui menu sidebar-toggle-menu right"><a id="toggle-right-sidebar-button" class="item"><i class="angle double right icon"></i><span class="grey item__text">Collapse</span></a></div></div>');
};
export { $rightSidebar as rightSidebar };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $rightSidebar.soyTemplateName = 'ts.base.perspective.sidebar.right.RightSidebarTemplate.rightSidebar';
}
