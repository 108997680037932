/// <reference types="semantic-ui" />
import $ from 'jquery';
import accordion from 'semantic-ui-accordion';
import checkbox from 'semantic-ui-checkbox';
import dimmer from 'semantic-ui-dimmer';
import dropdown from 'semantic-ui-dropdown';
import form from 'semantic-ui-form';
import modal from 'semantic-ui-modal';
import popup from 'semantic-ui-popup';
import progress from 'semantic-ui-progress';
import tab from 'semantic-ui-tab';
import transition from 'semantic-ui-transition';
import * as UIUtilsTemplate from 'soy/commons/UIUtilsTemplate.soy.generated';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as forms from 'ts-closure-library/lib/dom/forms';
import { TagName } from 'ts-closure-library/lib/dom/tagname';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/events/eventtype';
import * as soy from 'ts/base/soy/SoyRenderer';
import { StringUtils } from 'ts/commons/StringUtils';
import type { EventHandler } from 'ts/commons/UIUtils';
import { tsdom } from './tsdom';
import { UIUtils } from './UIUtils';

$.fn.accordion = accordion;
$.fn.checkbox = checkbox;
$.fn.dimmer = dimmer;
$.fn.dropdown = dropdown;
$.fn.form = form;
$.fn.modal = modal;
$.fn.popup = popup;
$.fn.progress = progress;
$.fn.tab = tab;
$.fn.transition = transition;

/** Helper class that encapsulates access to Semantic UI. */
export class SemanticUIUtils {
	/** The default options to use for searchable SemanticUI dropdowns (see also activateDropdowns()) */
	private static readonly DEFAULT_SEARCH_DROPDOWN_OPTIONS: Partial<SemanticUI.DropdownSettings> = {
		fullTextSearch: 'exact',
		selectOnKeydown: false,
		forceSelection: false
	};

	/**
	 * Activates a SemanticUI dropdown for the given element with optional settings defined at
	 * https://semantic-ui.com/modules/dropdown.html#/settings
	 *
	 * @param options Optional settings for the dropdown(s) behavior.
	 */
	public static activateDropdown(dropdownElement: Element, options: Partial<SemanticUI.DropdownSettings> = {}): void {
		$(dropdownElement).dropdown(options as SemanticUI.DropdownSettings);
		// Ensure that no error is printed in the console when a dropdown selection leads to a navigation event
		// and therefore the default hide animation fails, because the menu to transition was already removed from the DOM
		$.fn.transition.settings.silent = true;
	}

	/** Adds a label to a multi-select dropdown. */
	public static addDropdownLabel(textField: Element, label: string): void {
		// @ts-ignore
		$(textField).dropdown('add label', label, label);
	}

	/**
	 * Activates multiple SemanticUI dropdowns for the given elements with optional settings defined at
	 * https://semantic-ui.com/modules/dropdown.html#/settings
	 *
	 * @param options Optional settings for the dropdown(s) behavior.
	 */
	public static activateDropdowns(
		dropdownElements: Element[] | NodeListOf<Element>,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		dropdownElements.forEach((dropdownElement: Element) =>
			SemanticUIUtils.activateDropdown(dropdownElement, options)
		);
	}

	/** Returns the default search options together with the additional options. */
	private static getSearchOptions(
		additionalOptions: Partial<SemanticUI.DropdownSettings>
	): Partial<SemanticUI.DropdownSettings> {
		// Disabled as the suggested change slows down the Typescript compiler by ~20sec
		// eslint-disable-next-line prefer-object-spread
		const options = Object.assign({}, SemanticUIUtils.DEFAULT_SEARCH_DROPDOWN_OPTIONS);
		return Object.assign(options, additionalOptions);
	}

	/**
	 * Activates searchable SemanticUI dropdown for the element using the default search options (e.g. full text
	 * search).
	 *
	 * @param options According to https://semantic-ui.com/modules/dropdown.html#/settings
	 */
	public static activateSearchableDropdown(
		element: Element,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		SemanticUIUtils.activateDropdown(element, SemanticUIUtils.getSearchOptions(options));
	}

	/**
	 * Activates searchable SemanticUI dropdowns for dropdown elements matching the given IDs using the default search
	 * options (e.g. full text search). NOTE: This has to be done after the elements have been appended to the DOM.
	 *
	 * @param options According to https://semantic-ui.com/modules/dropdown.html#/settings
	 */
	public static activateSearchableDropdowns(
		dropdownElements: Element[] | NodeListOf<Element>,
		options: Partial<SemanticUI.DropdownSettings> = {}
	): void {
		dropdownElements.forEach((dropdownElement: Element) => {
			SemanticUIUtils.activateSearchableDropdown(dropdownElement, options);
		});
	}

	/**
	 * Adds a Semantic UI dropdown _menu_ as last child of the given dropdown activating element. This can be useful as
	 * it avoids having to specify an empty menu in a SOY template when dynamically populating dropdown items.
	 */
	public static addMenuToDropdownElement(activatingElement: Element): void {
		activatingElement.classList.toggle('dropdown', true);
		const menuElement = dom.createDom(TagName.DIV, { class: 'menu' });
		activatingElement.appendChild(menuElement);
	}

	/**
	 * Adds an action to the given dropdown element without extra space for an optional icon. After adding all items the
	 * dropdown element must be (re-)activated.
	 *
	 * @param dropdown The dropdown element.
	 * @param id The id of the item.
	 * @param title The title of the dropdown item.
	 * @param isLink The check if the item should be rendered as link.
	 * @param callback The callback for when the item is clicked.
	 * @returns The inserted action item.
	 */
	public static addDropdownAction(
		dropdown: Element,
		id: string,
		title: string,
		isLink: boolean,
		callback?: EventHandler
	): Element {
		const dropdownAction = soy.renderAsElement(UIUtilsTemplate.dropdownAction, {
			id,
			title,
			isLink
		});
		if (callback != null) {
			events.listen(dropdownAction, EventType.CLICK, UIUtils.preventDefaultEventAction(callback));
		}
		dom.getElementByClass('menu', dropdown)!.appendChild(dropdownAction);
		return dropdownAction;
	}

	/**
	 * Determines whether a dropdown element is on the upper or the lower side of the browser's viewpoint and returns
	 * the direction (downward or upward) depending on that. Caution: Element needs to be in the DOM or the return value
	 * will always be 'downward'
	 */
	public static getDropdownDirection(dropdownElement: Element): 'upward' | 'downward' {
		const clientY = dropdownElement.getBoundingClientRect().top;
		if (clientY < $(window).height()! / 2) {
			return 'downward';
		} else {
			return 'upward';
		}
	}

	/**
	 * Performs the validation of the form with the given element and rules.
	 *
	 * @param formElement The element of the form to validate.
	 * @param rules The semantic ui rules to use for validation.
	 * @param customRules Map for custom rule functions mapping name to validator function.
	 * @returns True if the form is valid.
	 */
	public static validateForm(
		formElement: Element,
		rules: SemanticUI.FormSettings | SemanticUI.Form.Fields,
		customRules?: Record<string, (p: unknown) => boolean>
	): boolean {
		const rulesBackup: Record<string, (this: HTMLElement, ...args: unknown[]) => boolean> = {};
		const currentRules: Record<string, (this: HTMLElement, ...args: unknown[]) => boolean> =
			$.fn.form.settings.rules!;
		const jqueryForm = $(formElement);

		// Set new rules and backup current ones.
		if (customRules != null) {
			for (const key in customRules) {
				const value = customRules[key]!;
				rulesBackup[key] = currentRules[key]!;
				currentRules[key] = value;
			}
		}
		jqueryForm.form(rules);
		jqueryForm.form('validate form');
		const isValid = jqueryForm.form('is valid');

		// Restore old rules.
		if (customRules != null) {
			for (const key in customRules) {
				currentRules[key] = rulesBackup[key]!;
			}
		}
		return isValid;
	}

	/**
	 * Activates a SemanticUI popup (a 'floating dialog') on the page.
	 *
	 * @param popupElement The element the popup should attach to (not the popup element itself)
	 * @param options Additional options (see SemanticUI page)
	 * @see closePopup
	 */
	public static activatePopup(popupElement: Element, options: Partial<SemanticUI.PopupSettings> = {}): void {
		const createdPopup = $(popupElement).popup(options as SemanticUI.PopupSettings);
		if (options.on === 'manual') {
			createdPopup.popup('show');
		}
	}

	/**
	 * Activates a SemanticUI tab on the page
	 *
	 * @param tabsCssSelector The selector for the menu-tab element
	 * @param options Additional options (see SemanticUI page)
	 */
	public static activateTabs(
		tabsCssSelector = '.ui.menu .item.tab',
		options: Partial<SemanticUI.TabSettings> = {}
	): void {
		$(tabsCssSelector).tab(options as SemanticUI.TabSettings);
	}

	public static selectTab(tab: HTMLElement): void {
		const parent = $(tab).parent();

		const activeTab = parent.find('.tab.active');
		activeTab.removeClass('active');
		tab.classList.add('active');

		const activeItem = parent.find('.item.active');
		activeItem.removeClass('active');
		const newActiveItem = parent.find(`.item[data-tab='${tab.dataset.tab!}']`);
		newActiveItem.addClass('active');
	}

	/**
	 * Activates a SemanticUI modal.
	 *
	 * @param modalElement The modal element that should be displayed
	 */
	public static showModal(modalElement: Element, options: Partial<SemanticUI.ModalSettings> = {}): void {
		$(modalElement)
			.modal(options as SemanticUI.ModalSettings)
			.modal('show');
	}

	/** Sets the progress of a Semantic UI progress bar. */
	public static setProgressLabel(progressBar: Element, label: string | undefined | null): void {
		if (label == null) {
			label = '';
		}
		$(progressBar).progress('set label', label);
	}

	/** Sets the progress of a Semantic UI progress bar. */
	public static setProgress(progressBar: Element, options: Partial<SemanticUI.ProgressSettings> = {}): void {
		$(progressBar).progress(options as SemanticUI.ProgressSettings);
	}

	/** Sets the progress bar to an error state. */
	public static setProgressError(progressBar: Element): void {
		$(progressBar).progress('set error');
	}

	/**
	 * Adds or removes a progress indicator to the given button. The button is disabled if a spinner should be shown and
	 * enabled otherwise.
	 *
	 * @static
	 */
	public static setShowButtonSpinner(button: Element, showAsSpinnerAndDisable: boolean): void {
		button.classList.toggle('loading', showAsSpinnerAndDisable);
		forms.setDisabled(button, showAsSpinnerAndDisable);
	}

	/**
	 * Adds the given callback as on change listener to a dropdown.
	 *
	 * @param dropdown The dropdown element.
	 * @param callback Callback to be called when the selection of the dropdown changes.
	 */
	public static addOnChangeListener(dropdown: Element, callback: () => void): void {
		$(dropdown).dropdown('setting', 'onChange', callback);
	}

	/**
	 * Adds given callbacks as on checked and on unchecked listeners to a checkbox group.
	 *
	 * @param checkboxSelector Css selector of the button group
	 * @param onCheckedCallback Callback to be called when a checkbox is checked
	 * @param onUncheckedCallback Callback to be called when a checkbox is unchecked.
	 */
	public static addCheckboxListeners(
		checkboxSelector: string,
		onCheckedCallback: (this: HTMLInputElement) => unknown,
		onUncheckedCallback: (this: HTMLInputElement) => unknown
	): void {
		$(checkboxSelector).checkbox({ onChecked: onCheckedCallback, onUnchecked: onUncheckedCallback });
	}

	/**
	 * Adds given callbacks as on checked and on unchecked listeners to a group.
	 *
	 * @param onCheckedCallback Callback to be called when a checkbox is checked
	 * @param onUncheckedCallback Callback to be called when a checkbox is unchecked.
	 */
	public static addRadioButtonListeners(
		radioButtons: ArrayLike<Element>,
		onCheckedCallback: (this: HTMLInputElement) => void,
		onUncheckedCallback?: (this: HTMLInputElement) => void
	): void {
		$(radioButtons).checkbox({ onChecked: onCheckedCallback, onUnchecked: onUncheckedCallback });
	}

	/**
	 * Retrieves all elements from a tag input field (multi searchable dropdown) even those that have not been submitted
	 * yet.
	 *
	 * @param tagsInput The tags input element to get tags from
	 */
	public static getAllTags(tagsInput: Element): string[] {
		const tags = forms.getValue(tagsInput) as string[] | null;
		const tagsContainer = tagsInput.parentElement;
		const incompleteTagElement = dom.getElementByClass('sizer', tagsContainer);
		const incompleteTag = dom.getTextContent(incompleteTagElement).trim();
		if (!StringUtils.isEmptyOrWhitespace(incompleteTag)) {
			if (tags == null) {
				return [incompleteTag];
			}
			return tags.concat(incompleteTag);
		}
		if (tags != null && Array.isArray(tags)) {
			return asserts.assertArray(tags) as string[];
		}
		return [];
	}

	/** Sets the selected value of the Semantic UI dropdown. */
	public static setDropdownValue(dropdownElement: Element, value: string | null | string[]): void {
		$(dropdownElement).dropdown('set selected', value);
	}

	/** Removes the selected value of the Semantic UI dropdown. */
	public static removeValueFromDropdown(dropdownElement: Element, value: string | null | string[]): void {
		$(dropdownElement).dropdown('remove selected', value);
	}

	/**
	 * Sets the selected text of the Semantic UI dropdown. In contrast to {@link #setDropdownValue}, the text does not
	 * have to be an actual option of the dropdown (and setting it won't change the selected dropdown value).
	 */
	public static setDropdownText(dropdownElement: Element, text: string): void {
		$(dropdownElement).dropdown('set text', text);
	}

	/** Sets multiple selected values of the Semantic UI multiple select element. */
	public static setMultipleSelectionDropdownValues(dropdownElement: Element, values: string[]): void {
		$(dropdownElement).dropdown('set exactly', values);
	}

	/**
	 * Returns either the string if text is a string or null if it's either undefined or null. Otherwise an assertion
	 * error is thrown.
	 */
	private static assertStringNullOrUndefined(text: string | null): string | null {
		if (text == null) {
			return null;
		}
		return asserts.assertString(text);
	}

	/** Gets the selected value or values of a Semantic UI dropdown element. */
	public static getDropdownValue(dropdownElement: Element): string | null {
		return SemanticUIUtils.assertStringNullOrUndefined($(dropdownElement).dropdown('get value'));
	}

	/** Gets the selected value or values of a Semantic UI dropdown element. */
	public static getMultipleSelectionDropdownValues(dropdownElement: Element): string[] {
		const listOrString = $(dropdownElement).dropdown('get value');
		let values: string[] = [];
		if (listOrString != null && listOrString !== '') {
			// Depending on whether a <select> or <div> is used, SemanticUI will return a comma-separated string or
			// an array of strings
			if (typeof listOrString === 'string') {
				values = listOrString.split(',');
			} else if (Array.isArray(listOrString)) {
				values = listOrString.filter(element => typeof element === 'string');
			}
		}
		asserts.assertArray(values);
		const parentElement = tsdom.getParentElement(dropdownElement);
		const fixedLabels = tsdom.getElementsByClass('ui.label.unmodifiable', parentElement);
		const fixedValues = Array.from(fixedLabels).map(label => label.dataset.value!);
		const allValues = values.concat(fixedValues);
		allValues.forEach(value => asserts.assertString(value));
		return allValues;
	}

	/** Restores the default text and value of the Semantic UI dropdown element. */
	public static resetDropdownElement(dropdownElement: Element): void {
		const dropdown = $(dropdownElement);
		dropdown.dropdown('restore default text');
		dropdown.dropdown('restore default value');
	}

	/**
	 * Activates a Semantic UI accordion element.
	 *
	 * @param exclusive Whether always only one area of the accordion can be expanded. Whether the accordion can be
	 *   triggered by clicking on the title. If set to false only the icon is working as trigger.
	 */
	public static activateAccordion(accordionElement: Element, exclusive = true, triggerOnTitle = true): void {
		if (triggerOnTitle) {
			$(accordionElement).accordion({ exclusive });
			return;
		}
		$(accordionElement).accordion({
			exclusive,
			selector: { trigger: '.title.accordion-trigger,.title > .accordion-trigger' }
		});
	}

	/** Activates Semantic UI checkboxes (by default a toggle-styled checkbox is assumed) */
	public static activateCheckboxes(cssSelector = '.ui.checkbox.toggle'): void {
		$(cssSelector).checkbox();
	}

	/**
	 * Activates Semantic UI radio buttons. After the activation, clicking on a label next to the radio button will also
	 * change its state.
	 */
	public static activateRadioButtons(radioButtons: ArrayLike<Element>): void {
		$(radioButtons).checkbox();
	}

	/** Check if Semantic UI radio checkbox identified by given id is checked. */
	public static isCheckboxCheckedById(id: string): boolean {
		return $('.ui.checkbox')
			.find('#' + id)
			.is(':checked');
	}

	/** Checks if Semantic UI checkbox identified by given selector is checked. */
	public static isCheckboxChecked(cssSelector = '.ui.checkbox'): boolean {
		return $(cssSelector).checkbox('is checked');
	}

	/**
	 * Gets the value of HTML element (by default text input is assumed).
	 *
	 * @param cssSelector
	 */
	public static getElementValue(cssSelector = ':text'): string | undefined | number | string[] {
		return $(cssSelector).val();
	}

	/**
	 * Closes an open popup.
	 *
	 * @param popup Either the id of the popup itself (string) or the activating element (Element)
	 * @see activatePopup
	 */
	public static closePopup(popup: Element | string): void {
		let jqueryPopup;
		if (typeof popup === 'string') {
			jqueryPopup = $('#' + popup);
		} else {
			jqueryPopup = $(popup);
		}
		jqueryPopup.popup('hide');
	}

	/**
	 * Adds or removes a loading indicator to or from the given element.
	 *
	 * @param element The Semantic element (button, dropdown, ...)
	 * @param isLoading Whether the element should show a loading indicator
	 */
	public static setLoading(element: Element, isLoading: boolean): void {
		element.classList.toggle('loading', isLoading);
	}

	/** Shows a loading indicator while waiting for async tasks all to finish. */
	public static async showLoadingIndicatorWhileTasksInProgress(
		loadingElement: Element,
		tasksToAwait: Array<Promise<void>>
	) {
		this.setShowButtonSpinner(loadingElement, true);
		await Promise.all(tasksToAwait).then(() => {
			this.setShowButtonSpinner(loadingElement, false);
		});
	}
}
