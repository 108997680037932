import { useLayoutEffect, useState } from 'react';
import { useWindowSize } from 'ts/base/hooks/WindowSizeHook';
import { useLocalStorage } from 'ts/commons/hooks/UseLocalStorage';

/**
 * The window width threshold (in pixels) for when to minimize the left sidebar automatically. This is a little bit
 * smaller than the 1024 pixels the UI tests are supposed to use, because for some unknown reason, the window width is
 * actually smaller than what is passed to Selenium (tested with Chrome).
 */
export const THRESHOLD_TO_MINIMIZE_SIDEBARS = 1008;

/**
 * Determines the state of the sidebar (collapsed/expanded) and provides a function to toggle the sidebar manually.
 *
 * @param id The id of the sidebar to collapse/expand
 */
export function useSidebarToggle(id = 'left'): [boolean, () => void] {
	const [collapsedByUser, setCollapsedByUser] = useLocalStorage<boolean | null>(
		`base-show-collapsed-${id}-sidebar`,
		null
	);
	const [collapsedBySize, setCollapsedBySize] = useState(false);
	const windowSize = useWindowSize();
	useLayoutEffect(() => {
		const windowWidth = windowSize.width;
		if (windowWidth < THRESHOLD_TO_MINIMIZE_SIDEBARS && !collapsedBySize) {
			setCollapsedBySize(true);
		} else if (windowWidth >= THRESHOLD_TO_MINIMIZE_SIDEBARS && collapsedBySize) {
			setCollapsedBySize(false);
			if (!collapsedByUser) {
				setCollapsedByUser(null);
			}
		}
	}, [collapsedBySize, collapsedByUser, setCollapsedByUser, windowSize.width]);
	const collapsed = collapsedByUser ?? collapsedBySize;

	const toggleManually = () => {
		if (collapsedByUser && !collapsedBySize) {
			setCollapsedByUser(null);
		} else {
			setCollapsedByUser(!collapsed);
		}
	};
	return [collapsed, toggleManually];
}
