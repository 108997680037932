import { QUERY } from 'api/Query';
import * as PerspectiveBaseTemplate from 'soy/base/scaffolding/PerspectiveBaseTemplate.soy.generated';
import { assertObject } from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { LegacyRightSidebar } from 'ts/base/perspective/sidebar/right/LegacyRightSidebar';
import { THRESHOLD_TO_MINIMIZE_SIDEBARS } from 'ts/base/perspective/sidebar/SidebarToggleHook';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { KeyboardShortcutRegistry } from 'ts/base/scaffolding/KeyboardShortcutRegistry';
import * as soy from 'ts/base/soy/SoyRenderer';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { PermissionUtils } from 'ts/commons/permission/PermissionUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { tsdom } from 'ts/commons/tsdom';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';
import { EProjectPermission } from 'typedefs/EProjectPermission';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { ETrafficLightColor } from 'typedefs/ETrafficLightColor';
import type { TeamscaleServiceClient } from '../client/TeamscaleServiceClient';

/**
 * Base class for Teamscale views. A view encapsulates the visual state of a perspective, i.e. a perspective can have
 * multiple views. Only one view is displayed at a time, depending on user input or the data to be displayed.
 *
 * The life-cycle of a view is <ul> <li>init: perform deferred initialization</li> <li>preloadContentAsync: fetch data
 * that should be available at rendering time</li> <li>isRightSidebarVisible: de/activate
 * sidebar</li><li>appendRightSidebarContent: fill in sidebar if required</li> <li>appendMainContent: fill in main page
 * content</li><li>dispose: before the view is removed, this is called to allow clean up</li> </ul>
 */
export abstract class TeamscaleViewBase {
	/** Separator used for the page title. */
	public static TITLE_SEPARATOR = ' \u00b7 ';

	/** The client to be used for fetching data. Will be non-null after {#init()} was called. */
	protected client: TeamscaleServiceClient | null = null;

	/** Will be non-null after {@link #init()} was called. */
	protected perspectiveContext: ExtendedPerspectiveContext | null = null;

	/** The default branch name. */
	protected defaultBranchName: string | null = null;

	/** Registry for keyboard shortcuts. May remain null even after initialization. */
	protected shortcutRegistry: KeyboardShortcutRegistry | null = null;

	/** The right sidebar. */
	public rightSidebar?: LegacyRightSidebar;

	/** The right sidebar when rendered with React. */
	private rightSidebarElement?: Element;

	/** The view descriptor. */
	public viewDescriptor: ViewDescriptor | null = null;

	/** Remembers whether the current view has already been disposed. */
	protected disposed = false;

	/** Performs additional initialization steps after construction. */
	public init(
		client: TeamscaleServiceClient,
		perspectiveContext: ExtendedPerspectiveContext,
		defaultBranchName: string | null,
		shortcutRegistry: KeyboardShortcutRegistry
	): void {
		this.client = client;
		this.perspectiveContext = perspectiveContext;
		this.shortcutRegistry = shortcutRegistry;
		this.defaultBranchName = defaultBranchName;
	}

	public getPerspectiveContext(): ExtendedPerspectiveContext {
		return assertObject(this.perspectiveContext);
	}

	public getClient(): TeamscaleServiceClient {
		return assertObject(this.client, "Client can't be null when loading data.");
	}

	/**
	 * Allows to load data asynchronously. The append methods for creating page content are called only after the data
	 * is available.
	 */
	public async preloadContentAsync(): Promise<void> {
		// Empty
	}

	/** Allows set a view specific suffix for the browser title. */
	public getViewTitle(): string | null {
		return this.viewDescriptor?.name ?? null;
	}

	/**
	 * Template method for showing/hiding the right sidebar.
	 *
	 * @returns <code>true</code> iff the sidebar should be shown.
	 */
	protected isRightSidebarVisible(): boolean {
		return false;
	}

	/** Template method for adding the sidebar content of the view. */
	protected appendRightSidebarContent(container: HTMLElement): void {
		container.appendChild(soy.renderAsElement(PerspectiveBaseTemplate.notImpl));
	}

	/** Renders the view into the given container element including the surrounding padding layout. */
	public renderInto(
		element: HTMLElement,
		viewContent: HTMLElement,
		activePerspective: ETeamscalePerspective,
		hash: NavigationHash
	): void {
		if (this.disposed) {
			return;
		}
		this.setTitle(activePerspective);
		const viewId = activePerspective.name + '-' + hash.getViewName();
		this.appendRightSidebar(element, viewId);
		this.appendMainContent(viewContent);
	}

	/** Sets the browser title. */
	private setTitle(activePerspective: ETeamscalePerspective): void {
		document.title = activePerspective.displayName;
		const viewTitle = this.getViewTitle();
		if (viewTitle != null) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + viewTitle;
		}
		const project = NavigationHash.getProject();
		if (!StringUtils.isEmptyOrWhitespace(project)) {
			document.title += TeamscaleViewBase.TITLE_SEPARATOR + project;
		}
	}

	private appendRightSidebar(container: Element, viewId: string): void {
		const element = this.renderRightSidebar();
		if (element != null) {
			this.rightSidebarElement = ReactUtils.append(element, container, this.getPerspectiveContext());
			return;
		}
		if (this.isRightSidebarVisible()) {
			this.rightSidebar = new LegacyRightSidebar(container, viewId);
			const renderRightSidebarCollapsed =
				dom.getViewportSize().width < THRESHOLD_TO_MINIMIZE_SIDEBARS || this.rightSidebar.isCollapsedByUser();
			const rightSidebar = tsdom.getElementById('right-sidebar');
			this.appendRightSidebarContent(rightSidebar);

			if (renderRightSidebarCollapsed && !this.rightSidebar.isCollapsed()) {
				this.rightSidebar.toggle(true);
			}
		}
	}

	/**
	 * Renders the right sidebar in case it is completely implemented in React. When the method returns a non-null value
	 * #isRightSidebarVisible and #appendRightSidebarContent are ignored.
	 */
	protected renderRightSidebar(): JSX.Element | null {
		return null;
	}

	/**
	 * Template method for adding the main content of the view.
	 *
	 * @param element The element to add the content to.
	 */
	protected abstract appendMainContent(element: HTMLElement): void;

	/** Allows to clean up resources or unregister listeners when the view is no longer needed. */
	public dispose(): void {
		this.disposed = true;
		this.rightSidebar?.dispose();
		ReactUtils.unmount(this.rightSidebarElement);
	}

	/** Loads the default branch name for the given project. */
	protected async loadDefaultBranchName(project: string): Promise<void> {
		this.defaultBranchName = await QUERY.getDefaultBranchNameGetRequest(project).fetch();
	}

	/** Returns true if the current user has permission to edit tasks for the the current project. */
	protected mayEditTasks(project: string): boolean {
		return this.hasProjectPermission(project, EProjectPermission.EDIT_TASKS);
	}

	/** Returns true if the current user has permission to update the status of tasks from the current project. */
	protected mayUpdateTaskStatus(project: string): boolean {
		return (
			this.hasProjectPermission(project, EProjectPermission.EDIT_TASKS) ||
			this.hasProjectPermission(project, EProjectPermission.UPDATE_TASK_STATUS)
		);
	}

	/** Returns true if the current user has permission to update the resolution of tasks from the current project. */
	protected mayUpdateTaskResolution(project: string): boolean {
		return (
			this.hasProjectPermission(project, EProjectPermission.EDIT_TASKS) ||
			this.hasProjectPermission(project, EProjectPermission.UPDATE_TASK_RESOLUTION)
		);
	}

	/**
	 * Returns true if the current user has permission to edit the findings blacklist for findings with a specific
	 * assessment type in the current project.
	 *
	 * @param project
	 * @param assessment Type of the finding
	 */
	protected mayEditBlacklistForAssessmentType(project: string, assessment: string): boolean {
		switch (assessment) {
			case ETrafficLightColor.YELLOW.name:
				return this.hasProjectPermission(project, EProjectPermission.EXCLUDE_YELLOW_FINDINGS);
			case ETrafficLightColor.RED.name:
				return this.hasProjectPermission(project, EProjectPermission.EXCLUDE_RED_FINDINGS);
			default:
				return false;
		}
	}

	/** Returns true if the current user has permission to edit architectures for the project. */
	protected mayEditArchitectures(project: string): boolean {
		return this.hasProjectPermission(project, EProjectPermission.EDIT_ARCHITECTURES);
	}

	/** Returns true if the current user has permissions to edit global roles. */
	protected mayEditGlobalRoles(): boolean {
		return this.hasGlobalPermission(EGlobalPermission.EDIT_GLOBAL_ROLES);
	}

	/** Returns true if the current user has permissions to edit roles. */
	protected mayEditRoles(): boolean {
		return this.hasGlobalPermission(EGlobalPermission.EDIT_ROLES);
	}

	/** Returns true if the current user has the given global permission. */
	protected hasGlobalPermission(globalPermission: EGlobalPermission): boolean {
		return PermissionUtils.hasGlobalPermission(this.perspectiveContext!, globalPermission);
	}

	/** Returns true if the current user has the given project permission for the project or project alias. */
	protected hasProjectPermission(project: string, projectPermission: EProjectPermission): boolean {
		return PermissionUtils.hasProjectPermission(this.perspectiveContext, project, projectPermission);
	}

	public setViewDescriptor(viewDescriptor: ViewDescriptor): void {
		this.viewDescriptor = viewDescriptor;
	}
}
